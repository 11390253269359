/** @format */

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { getTheme } from './utils/theme';
import * as Sentry from '@sentry/react';
import initAxois from './utils/api';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const theme = getTheme();

Sentry.init({
	dsn: 'https://c873278dc9bd27ac9f3d26c2a770e1c0@o1297842.ingest.sentry.io/4506058774151168',
	integrations: [
		new Sentry.BrowserTracing({
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: ['localhost', /^https:\/\/bureau\.id\//],
		}),
		new Sentry.Replay(),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, // Capture 100% of the transactions
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

initAxois()

root.render(
	// <React.StrictMode>
		<Router>
			<ChakraProvider theme={theme}>
				<App />
			</ChakraProvider>
		</Router>
	// </React.StrictMode>,
);
