/** @format */

import {
  Flex,
  Container,
  Heading,
  Button,
  Image,
  Text,
  ListItem,
  UnorderedList,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, { ReactElement, useEffect, useState } from "react";
import { Footer } from "./Footer";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { detect } from "detect-browser";
import { ValidateApiResponse } from "../types/types";
import { KYCCompleted } from "./KYCCompleted";

export const AppLandingCard = (): ReactElement => {
  const digilockerGuideModal = useDisclosure();
  const termsOfUseModal = useDisclosure();
  const [validateResponse, setValidateResponse] =
    useState<ValidateApiResponse>();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isRefIdValid, setIsRefIdValid] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [kycCompleted, setKycCompleted] = React.useState(false);

  const browser = detect();
  const browserName = browser?.name;
  const browserOs = browser?.os;
  const browserVersion = browser?.version;

  const { search } = useLocation();
  const refId = new URLSearchParams(search).get("refId");

  useEffect(() => {
    setIsLoading(true);

    (async () => {
      try {
        const response = await axios({
          url: `${process.env.REACT_APP_BASE_URL}service/digilocker/validate`,
          headers: {
            authkey: "27e745fe-6c24-4f1f-b2c0-d81adb4f6f04",
            "Content-Type": "application/json",
          },
          method: "POST",
          data: {
            refId,
            browser: `${browserName} ${browserOs} ${browserVersion}`,
          },
        });
        if (response?.data?.callback_url)
          localStorage.setItem("callbackUrl", response.data.callback_url);
        if (response?.data?.cancel_url) {
          localStorage.setItem("cancelUrl", response.data.cancel_url);
        }

        setValidateResponse(response?.data);

        if (response?.data?.message === "KYC Completed") {
          setIsRefIdValid(false);
          setKycCompleted(true);
          setIsLoading(false);
        }
      } catch (error) {
        setError("Service Unavailable!");
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const consentBody = {
    refId,
    receivedConsent: validateResponse?.consent,
  };

  const validateDigilocker = () => {
    setIsLoading(true);
    (async () => {
      try {
        var config = {
          method: "post",
          url: `${process.env.REACT_APP_BASE_URL}service/digilocker/consent`,
          headers: {
            authkey: "27e745fe-6c24-4f1f-b2c0-d81adb4f6f04",
            "Content-Type": "application/json",
          },
          data: consentBody,
        };
        const res = await axios(config);
        if (res.data && res.data.url) {
          // Redirect user to the URL
          window.location = res.data.url;
        } else {
          // Handle the case where URL is not in the response
          setError("URL not found in response");
        }
      } catch (error) {
        console.error("error", error);
        setError("Service Unavailable!");
      } finally {
        setLoading(false);
      }
    })();
  };

  return (
    <>
      <Flex
        justifyContent="center"
        align="center"
        h="100vh"
        w="full"
        bg="gray.100"
        py="2"
        direction="column"
      >
        <Container
          w={["100%", "31.6rem", "31.6rem"]}
          h="auto"
          bg="white"
          boxShadow="md"
          borderRadius="lg"
        >
          {kycCompleted ? (
            <KYCCompleted />
          ) : (
            <>
              <Flex>
                <Image src="/assets/images/document.svg" py="10" />
              </Flex>
              <Flex direction="column">
                <Heading fontSize="xl" color="gray.800" fontWeight="bold">
                  KYC Verification with Digilocker
                </Heading>
                <Text
                  color="gray.600"
                  py="2"
                  fontWeight="medium"
                  fontSize="sm"
                  mb="6"
                >
                  Your Mobile Number should be linked with Aadhaar to receive
                  OTP
                </Text>
              </Flex>
              <Flex
                bg="white.50"
                justify="start"
                align="start"
                direction="column"
                borderRadius="xl"
                p="4"
              >
                <Flex direction="row" align="center">
                  <Image src="/assets/images/digilocker.svg" />
                  <Flex direction="column">
                    <Text
                      fontSize={["xs", "sm", "sm"]}
                      pl="2"
                      color="gray.800"
                      fontWeight="bold"
                    >
                      DigiLocker
                    </Text>
                    <Text
                      fontSize={["xs", "sm", "sm"]}
                      pl="2"
                      color="gray.600"
                      fontWeight="medium"
                    >
                      Indian government cloud storage initiative.
                    </Text>
                  </Flex>
                </Flex>

                <UnorderedList mt="8" ml="6">
                  <ListItem color="gray.600" pb="1" fontWeight="medium">
                    Fast KYC verification.{" "}
                  </ListItem>
                  <ListItem color="gray.600" pb="1" fontWeight="medium">
                    Digilocker automatically verifies your documents.
                  </ListItem>
                  <ListItem color="gray.600" pb="1" fontWeight="medium">
                    Your data is 100% safe and secure.
                  </ListItem>
                </UnorderedList>

                <Button
                  variant="link"
                  pb="2"
                  mt="8"
                  fontWeight="bold"
                  color="blue.400"
                  textAlign="left"
                  onClick={digilockerGuideModal.onOpen}
                >
                  Learn about DigiLocker
                </Button>
              </Flex>
              <Heading
                fontSize="sm"
                color="gray.600"
                mt="4"
                fontWeight="normal"
                display="flex"
              >
                By clicking on Allow & Proceed you agree to our{" "}
                <Text
                  color="blue.400"
                  onClick={termsOfUseModal.onOpen}
                  textDecoration="underline"
                  fontWeight="bold"
                  ml="1"
                >
                  {" "}
                  Terms of Use
                </Text>
              </Heading>
              <Flex justify="center" align="center" mt="8">
                <Button
                  borderRadius="lg"
                  bg="blue.400"
                  color="white"
                  fontWeight="medium"
                  mb="8"
                  px="10"
                  disabled={isLoading}
                  onClick={validateDigilocker}
                  _hover={{ bg: "blue.600", color: "white" }}
                >
                  Allow & Proceed
                </Button>
              </Flex>
            </>
          )}
        </Container>
        <Footer />
      </Flex>

      <Modal
        isOpen={digilockerGuideModal.isOpen}
        onClose={digilockerGuideModal.onClose}
        isCentered
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent bg="white">
          <ModalHeader>Digilocker Guide</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs variant="unstyled">
              <TabList>
                <Tab
                  _selected={{ color: "black", bg: "gray.300" }}
                  fontWeight="semibold"
                  borderRadius="md"
                >
                  How to Verify
                </Tab>
                <Tab
                  _selected={{ color: "black", bg: "gray.300" }}
                  fontWeight="semibold"
                  borderRadius="md"
                >
                  FAQs
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel p="0">
                  <Text
                    color="gray.600"
                    my="6"
                    fontWeight="medium"
                    fontSize="sm"
                  >
                    Digilocker is a government led initiative that aims to
                    eliminate the need for physical IDs and proofs by verifying
                    and storing official documents.
                  </Text>
                  <Text fontSize="sm" fontWeight="bold" color="black" mb="2">
                    Step 1
                  </Text>
                  <Text
                    color="gray.600"
                    fontWeight="normal"
                    fontSize="sm"
                    mb="4"
                  >
                    Ensure that your Aadhaar is linked with your current mobile
                    number.
                  </Text>
                  <Text fontSize="sm" fontWeight="bold" color="black" mb="2">
                    Step 2
                  </Text>
                  <Text
                    color="gray.600"
                    fontWeight="normal"
                    fontSize="sm"
                    mb="4"
                  >
                    Enter your Aadhaar number and sign-up using OTP/biometric
                    check. Or sign in if you already have an account.
                  </Text>
                  <Text fontSize="sm" fontWeight="bold" color="black" mb="2">
                    Step 3
                  </Text>
                  <Text
                    color="gray.600"
                    fontWeight="normal"
                    fontSize="sm"
                    mb="4"
                  >
                    You can now simply enter your document number (Aadhaar PAN
                    etc) to fetch the document from the respective database. If
                    some organisation has uploaded any of your e-documents, you
                    can see it in the account. You can also manually upload your
                    documents to Digilocker.
                  </Text>
                  <Text fontSize="sm" fontWeight="bold" color="black" mb="2">
                    Step 4
                  </Text>
                  <Text
                    color="gray.600"
                    fontWeight="normal"
                    fontSize="sm"
                    mb="4"
                  >
                    All set! You're now ready to use Digilocker to complete your
                    KYC in a seamless manner foranumber of apps.
                  </Text>
                </TabPanel>
                <TabPanel p="0">
                  <Text
                    fontSize="sm"
                    fontWeight="bold"
                    color="black"
                    mt="8"
                    mb="2"
                  >
                    What are the benefits of using DigiLocker?
                  </Text>
                  <UnorderedList mb="4">
                    <ListItem
                      color="gray.600"
                      fontWeight="normal"
                      fontSize="sm"
                    >
                      Important Documents Anytime, Anywhere!
                    </ListItem>
                    <ListItem
                      color="gray.600"
                      fontWeight="normal"
                      fontSize="sm"
                    >
                      Authentic Documents, Legally at Par with Originals
                    </ListItem>
                    <ListItem
                      color="gray.600"
                      fontWeight="normal"
                      fontSize="sm"
                    >
                      Digital Document Exchange with the consent of the citizen
                    </ListItem>
                    <ListItem
                      color="gray.600"
                      fontWeight="normal"
                      fontSize="sm"
                    >
                      Faster service Delivery- Government Benefits, Employment,
                      Financial Inclusion, Education, Health.
                    </ListItem>
                  </UnorderedList>

                  <Text fontSize="sm" fontWeight="bold" color="black" mb="2">
                    How can I sign up for DigiLocker?
                  </Text>
                  <Text
                    color="gray.600"
                    fontWeight="normal"
                    fontSize="sm"
                    mb="4"
                  >
                    Signing up for DigiLocker is easy-all you need is your
                    Aadhaar number (linked to your mobile number). Your Aadhaar
                    number will be authenticated by sending an OTP
                    (one-time-password).
                  </Text>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={digilockerGuideModal.onClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={termsOfUseModal.isOpen}
        onClose={termsOfUseModal.onClose}
        isCentered
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent bg="white">
          <ModalHeader>Digilocker Guide</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{validateResponse?.consent}</Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={termsOfUseModal.onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
