/** @format */

import { Flex, Image, Text } from '@chakra-ui/react';
import React from 'react';

export const Footer = (): React.ReactElement => (
	<Flex direction='column' justify='center' align='center' mt='6'>
		<Text mb={1} color='gray.500' textAlign='center' fontSize='xs'>
			Powered by
		</Text>
		<Image
			src='https://uploads-ssl.webflow.com/61a5d188c74343756e97d881/647bb473159f326b03a3c7e2_bureau_logohorizontal_bw_black%203.png'
			w='22'
			h='22'
		/>
	</Flex>
);
