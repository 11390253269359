/** @format */

import React, { ReactElement } from 'react';
import { Text, Image, Flex } from '@chakra-ui/react';

export const KYCCompleted = (): ReactElement => {
	return (
		<>
			<Flex
				h='full'
				px='2'
				py='8'
				mb='8'
				textAlign='center'
				direction='column'
				justify='center'
				alignItems='center'>
				<Image
					src='https://uploads-ssl.webflow.com/61a5d188c74343756e97d881/636b717ebfd4d81c76552ea5_congratulations.svg'
					h='120'
					w='120'
					mx='auto'
				/>
				<Text fontSize='xl' fontWeight='bold' textAlign='center' my='6'>
					Your KYC is already done
				</Text>
			</Flex>
		</>
	);
};
