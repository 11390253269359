/** @format */

import { Route, Routes } from 'react-router-dom';
import { AppLandingCard } from './components/AppCard';
import KycStatus from './screens/KycStatus';

function App() {
	return (
		<>
			<Routes>
				<Route path='/fetch/' element={<KycStatus />} />
				<Route path='/' element={<AppLandingCard />} />
			</Routes>
		</>
	);
}

export default App;
