/** @format */

import { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Footer } from "../components/Footer";
import { Success } from "../components/Success";
import { Flex, Container, Text } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";
import { Failed } from "../components/Failed";

const STATUS = {
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
  ERROR: "ERROR",
  MISSING_DOCUMENTS: "MISSING_DOCUMENTS",
};

export default function KycStatus() {
  const [errorMessage, setErrorMessage] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");
  const [status, setStatus] = useState(STATUS.LOADING);

  const { search } = useLocation();
  const refId = new URLSearchParams(search).get("state");
  const accessToken = new URLSearchParams(search).get("code");

  const getStatus = async () => {
    const data = {
      refId,
      accessToken,
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}service/digilocker/status`,
      headers: {
        authkey: "27e745fe-6c24-4f1f-b2c0-d81adb4f6f04",
        "Content-Type": "application/json",
      },
      data: data,
    };
    try {
      const res = await axios(config);
      const status = res?.data?.status || 0;
      if (status === 0) {
        setRedirectUrl(res.data.url || localStorage.getItem("cancelUrl"));
        setStatus(STATUS.FAILURE);
      } else if (status === 1) {
        setRedirectUrl(res.data.url || localStorage.getItem("callbackUrl"));
        setStatus(STATUS.SUCCESS);
      } else if (status === 2) {
        setStatus(STATUS.MISSING_DOCUMENTS);
        fetchDocuments(res.data.token, res.data.missing_doc);
      }
    } catch (error) {
      setErrorMessage("Service Unavailable!");
    }
  };

  const fetchDocuments = async (token: string, missing_doc: string) => {
    const data = {
      refId,
      accessToken: token,
      docName: missing_doc,
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}service/digilocker/pull-document`,
      headers: {
        authkey: "27e745fe-6c24-4f1f-b2c0-d81adb4f6f04",
        "Content-Type": "application/json",
      },
      data: data,
    };
    try {
      const res = await axios(config);
      const status = res?.data?.status || 0;
      if (status === 0) {
        setRedirectUrl(res.data.url || localStorage.getItem("cancelUrl"));
        setStatus(STATUS.FAILURE);
      } else if (status === 1) {
        setRedirectUrl(res.data.url || localStorage.getItem("callbackUrl"));
        setStatus(STATUS.SUCCESS);
      } else if (status === 2) {
        setStatus(STATUS.MISSING_DOCUMENTS);
      }
    } catch (error) {
      setErrorMessage("Service Unavailable!");
    }
  };

  useEffect(() => {
    getStatus();
  }, []);
  console.log({ status });
  if (status === STATUS.SUCCESS) {
    return (
      <>
        <Success redirectUrl={redirectUrl} />
        <Footer />
      </>
    );
  }

  if (status === STATUS.FAILURE) {
    return (
      <>
        <Failed redirectUrl={redirectUrl} />
        <Footer />
      </>
    );
  }

  return (
    <>
      <Flex
        h="100vh"
        justify="center"
        align="center"
        direction="column"
        bg="gray.100"
        px="2"
      >
        <Container
          w={["100%", "506px", "506px"]}
          height="600px"
          bg="white"
          boxShadow="md"
          borderRadius="lg"
        >
          <Flex
            h="full"
            px="2"
            py="8"
            mb="8"
            textAlign="center"
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Spinner size="xl" colorScheme="blue" />
            <Text fontSize="xl" fontWeight="bold" textAlign="center" my="6">
              Fetching documents from DigiLocker...
            </Text>
            <Text color="gray.600" mb="8" fontSize="sm" fontWeight="medium">
              Please don’t press back or exit this screen
            </Text>

            {errorMessage && <Text>{errorMessage}</Text>}
          </Flex>
        </Container>
        <Footer />
      </Flex>
    </>
  );
}
