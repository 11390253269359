/** @format */

import { extendTheme } from '@chakra-ui/react';
import { colors } from './colors';

export const getTheme = (): {} => {
	return extendTheme({
		fonts: {
			heading: 'Inter',
			body: 'Inter',
		},
		colors,
	});
};
