/** @format */

import axios from 'axios';

function initAxois() {
	if (process.env.NODE_ENV === 'development')
		axios.defaults.headers['x-bureau-auth-migrated-id'] = "bureauidinvoid"
	else
		axios.defaults.headers['authkey'] = process.env.REACT_APP_AUTH_KEY || ""
}


export default initAxois