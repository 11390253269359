/** @format */

import { useEffect, useState } from 'react';
import { Flex, Container, Image, Text } from '@chakra-ui/react';
import { Footer } from './Footer';

export const Failed = ({ redirectUrl }: { redirectUrl: string }): React.ReactElement => {
	const [count, setCount] = useState(5);

	useEffect(() => {
		if (count > 0) {
			setTimeout(() => {
				setCount(count - 1);
			}, 1000);
		}
	}, [count]);

	useEffect(() => {
		setTimeout(() => {
			window.location.href = redirectUrl;
		}, 5000);
	}, []);

	return (
		<Flex h='100vh' justify='center' align='center' direction='column' bg='gray.100' px='2'>
			<Container
				w={['100%', '506px', '506px']}
				height='600px'
				bg='white'
				boxShadow='md'
				borderRadius='lg'>
				<Flex
					h='full'
					px='2'
					py='8'
					mb='8'
					textAlign='center'
					direction='column'
					justify='center'
					alignItems='center'>
					<Image
						src='https://uploads-ssl.webflow.com/61a5d188c74343756e97d881/649af40de752cfc951022535_failure.svg'
						h='120'
						w='120'
						mx='auto'
					/>
					<Text fontSize='xl' fontWeight='bold' textAlign='center' my='6'>
						Failed
					</Text>
					<Text color='gray.600' mb='8' fontSize='sm' fontWeight='medium'>
						We failed to obtained your documents from DigiLocker.
					</Text>
					<Text color='gray.400' fontSize='sm'>
						Redirecting automatically in {count} Seconds
					</Text>
				</Flex>
			</Container>
			<Footer />
		</Flex>
	);
};
